import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../components/scss/app.scss"

const AboutKalotiPage = () => (
    <Layout>
    <SEO title="Selecting A Cosmetic Dentist | Porcelain Veneers | The Smile Stylist" 
    description="Want long lasting, natural looking porcelain veneers? Here is everything you need to know when looking to select the right cosmetic dentist for you." 
    keywords="Cosmetic Dentist, Cosmetic Dentistry" />
    <main className="site-content" role="main">

    <img className="img__full-width" src={ require('../images/desktop/home/selecting-cosmetic-dentists.png') } alt="Selecting Cosmetic Dentists Hero" />

    <section id="info" className="cosmetics__info">
        <div className="container">
      <p>
        Selecting the right dentist to perform your cosmetic procedure is the most crucial part of getting your dream
        smile right. Like any of your investments, it’s important that you do your homework, and that you do not fall
        for quick fix solutions that seem too good to be true.
      </p>
      <img className="cosmetics__hero-img" src={ require("../images/desktop/scd/what-every-patient-should-know.png" )} alt="What every patient should know" />
      <img className="cosmetics__img" src={ require("../images/desktop/scd/first.png" )} alt="First Point" />
      <p>There is no board-certified specialty in cosmetic dentistry. Which means that any general dentist is
        technically certified to give you the “smile makeover” that you’ve always dreamed of.</p>
      <p>The problem is that in reality, it takes many years and thousands of hours of experience to truly master the
        art of any cosmetic procedure. Yet, if you were to pick up the phone today and called 10 dental clinics, the
        majority of them would say they perform smile makeovers, full mouth restorations and porcelain veneers. </p>
      <p>But ask yourself this, if you are making an investment that you hope to last you the next 20 years, and you are
        trusting someone to design and craft your new smile do you really want a dentist that spends most of their days
        doing fillings, checkups and more general procedures to give you the dream smile and return on your investment
        that you are looking for? </p>
      <p>At The Smile Stylist we live and breath cosmetic procedures. Dr. Kaloti has over 20 years of experience in
        Canada and the US and uses his experience and skill set to help you make the best decisions regarding your oral
        health while ensuring to deliver you an unforgettable smile.
      </p>
      <p>It’s important to note that roughly 20% of our cosmetic procedures are on patients who were not happy with
        previous results performed by other dentists or who’s work had fallen off.</p>
      <img className="cosmetics__img" src={ require("../images/desktop/scd/second.png" )} alt="Second Point" />
      <p>Things have changed in cosmetic dentistry. The very top dentists are now using game changing dental technology
        that allows us to scan your face enabling us to design a perfect tailored smile using your bone structure and
        facial features. Don’t be fooled by cheap technology that uses tablets used in house to take your scans or using
        no scans at all, having the right technology will allow us to design a perfect smile just for you. At The Smile
        Stylist we won’t even do a smile makeover without this scan technology anymore. </p>
      <img className="cosmetics__img" src={ require("../images/desktop/scd/third.png" )} alt="Third Point" />
      <p>We’ve already established how important the technology component is. Think of the facial scans as photoshop, or
        any video editing equipment. </p>
      <p>
        They are tools that one can use to do incredible work; However, just because you have an incredible set of tools
        does not mean that:</p>
      <p>1) You’ll know how to use them properly or to the best of your ability </p>
      <p>2) That you are a very good artist </p>
      <p> Your smile is important to us and something that you’ll be using every day for the rest of your life. Crafting
        unforgettable smiles is an art form and one that takes thousands of hours to master. We are passionate about
        delivering the very best and that is what you should be expecting out of your dentist.</p>
      <img className="cosmetics__img" src={ require("../images/desktop/scd/fourth.png" )} alt="Fourth Point" />
      <p>The ONLY people that you should trust to put your oral health first, are dentists. Any body else trying to give
        you advice or send you to a particular set of dentists are just trying to make a quick buck off you. </p>
      <p>
        This is a massive decision that you are making and we encourage you to visit a number of dentists that you find
        appealing in order to help you make the best decision for you.
      </p>
      <p>Don’t fall into the trap and end up having to spend twice as much money in the long run because you didn’t do
        your homework. Ask the right questions and trust your gut!</p>
      <img className="cosmetics__img" src={ require("../images/desktop/scd/fifth.png" )} alt="Fifth Point" />
      <p>Getting cosmetic work done is a personal decision that takes time. You should never be bullied or pushed into a
        decision. Make sure you choose a dentist that is there to guide you and explain to you why he recommends a
        certain procedure over an another and one that feels confident enough for you to go get a second opinion. </p>
      <img className="cosmetics__img" src={ require("../images/desktop/scd/sixth.png" )} alt="Sixth Point" />
      <p>
        Most cosmetic procedures, like porcelain veneers, full mouth restorations or smile makeovers should not be
        rushed. If you are being sold on speed, this is a major red flag.
      </p>
      <p>Getting it right, and making sure you are happy and comfortable with your new smile takes time. Trying to
        deliver your case as quickly as possible and spinning it as great service is often a case of trying to pump as
        many patients through the door in an attempt to maximize revenue, that or its just a lack or expertise and care
        in the true craftsmanship of what it means to deliver an unforgettable smile. </p>
      <p>At the Smile Stylist, we sacrifice speed for perfection. We want you to have your temps in long enough for you
        to learn what it would feel like to have your brand new smile so that we can work together to make the little
        adjustments (which in reality are not so little) that will take your satisfaction of your new smile from a 6 or
        7 to a 10. </p>
        </div>
    </section>

    <section id="additional-info" className="cosmetics__additional-info">
        <div className="container">
                <a className="cosmetics__btn" href="/contact">
                    <p data-text="Free Consult" className="text-reflect">
                        Free Consult
                    </p> 
                </a>
            <div className="cosmetics cosmetics__finance">
                <p className="cosmetics__title">Financing your smile</p>
                <div className="w3-content w3-section">
                    <img alt="Girl Smile Befor After " className="mySlides" src={ require("../images/desktop/Before After  Cosmetic Dentist- The Smile Stylist.png" )} />
                    <img alt="Old Man Smile Before After " className="mySlides" src={ require("../images/desktop/Before After - Smile Stylist results.png" )} />
                    <img alt="Women Smile Before After" className="mySlides" src={ require("../images/desktop/Before After - The Smile Stylist.png" )} />
                    <img alt="Man Smile Before After " className="mySlides" src={ require("../images/desktop/Cosmetic Dentist Before and After - Clear Correct.png" )} />
                </div>
                <p className="cosmetics__info">Getting the dream smile that you have always wanted is more achievable than you probably realize. We have
                partnered up with the best finance providers in the country to ensure that you have every opportunity to get
                the smile you’ve always wanted without having to over extend yourself.</p>
            </div>
        </div>
    </section>
    </main>
  </Layout>
)

export default AboutKalotiPage